<template>
  <div style="min-height: 100vh;padding:10px">
    <div class="header">
      <van-row gutter="10">
        <van-col @click="onClickBack">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
            style="height: 25px"
          />
          <span style="font-size: 25px; color: white;font-weight:bold">用户注册</span>
        </van-col>
      </van-row>
    </div>
    <card style="margin-top: 30px;padding-bottom:2rem;height:68vh;overflow-y:scroll">
      <template #content>
        <fieldForm
          ref="fields"
          :submit="register"
          :modal="user"
          :fieldData="opFields"
          :submitBtnText="'注册'"
          v-on:formUpdate="mainFormUpdate"
          v-on:date-select="onDateSelect" 
        ></fieldForm>
      </template>
    </card>
    <van-popup v-model="showPicker" position="bottom" class="popup-top">
      <van-picker
        show-toolbar
        :columns="['男', '女']"
        @confirm="onGenderConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="showLevelPicker" position="bottom" class="popup-top">
      <van-picker
        show-toolbar
        :columns="['领队', '出境领队']"
        @confirm="onLevelConfirm"
        @cancel="showLevelPicker = false"
      />
    </van-popup>
    <van-action-sheet v-model="showDateTypeSelection" :actions="dateTypes" @select="onDateTypeSelect"
      cancel-text="取消" close-on-click-action/>
    <van-action-sheet v-model="showDateSelect" :overlay="true">
      <van-datetime-picker ref="dateSelector"
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        v-on:blur="emitChange()"
        @confirm="onDateConfirm"
        @cancel="showDateSelect = false"
      />
    </van-action-sheet>
  </div>
</template>

<script>
import api  from "@/services/apis.js"
import card from "@/views/components/Card"
import fieldForm from "@/views/components/FieldForm";
import { Toast } from 'vant';
import {
  validateNumberAndLetter,
  validateIdCard,
  validatePhone
} from "@/utils/validators"
export default {
  name: "register",
  components: {
    card,
    fieldForm
  },
  data() {
    return {
      passwordConfirm: "",
      showPicker: false,
      showLevelPicker: false,
      locationStr: "",
      showGuideFields: false,
      showJiangjieFields: false,
      showLocationSelection: false,
      showDateTypeSelection: false,
      dateTypes: [ { name: '具体日期' }, { name: '长期' } ],
      currentDateSelectItem: {},
      showDateSelect: false,
      currentDate: new Date(),
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 11, 31),
      user: {
        mobile: "",
        id_card: "",
        password: "",
        password_confirmation: "",
        name: "",
        gender: "",
        avatar: "",
        role_name: "",
        tourist_card: "",
        tourist_card_image: "",
        tourist_certificate: "",
        tourist_certificate_image: [],
        establish_date: "",
        valid_period: "",
        level: "",
        locationStr: "",
        province_code: "",
        city_code: "",
        district_code: "",
        language: ""
      },
      opFields: [],
      fields: [
        {
          title: "头像",
          name: "avatar",
          type: "file",
          rule: [],
          max_count: 1,
          inline: false,
        },
        {
          title: "姓名",
          name: "name",
          type: "text",
          required: true,
          rule: [
            { required: true, message: "" },
            {
              validator: this.nameLengthValidate,
              message: "名字过长"
            }
          ],
          inline: false,
        },
        {
          title: "性别",
          name: "gender",
          type: "select",
          values: ['男', '女'],
          required: true,
          rule: [{ required: true, message: "" }],
          inline: false
        },
        {
          title: "电话",
          name: "mobile",
          type: "text",
          required: true,
          inline: false,
          rule: [
            { required: true, message: "" },
            {
              validator: validatePhone,
              message: "请输入正确的电话格式"
            }
          ],
        },
        {
          title: "身份证号",
          name: "id_card",
          type: "text",
          inline: false,
          required: true,
          rule: [
            { required: true, message: "" },
            {
              validator: validateIdCard,
              message: "身份证格式不正确"
            }],
        },
        {
          title: "密码",
          name: "password",
          type: "password",
          inline: false,
          required: true,
          rule: [
            { required: true, message: "" },
            {
              validator: this.passwordLengthValidate,
              message: "密码长度最少为6位"
            }
          ],
        },
        {
          title: "确认密码",
          name: "password_confirmation",
          type: "password",
          inline: false,
          required: true,
          rule: [
            { required: true, message: "" },
            {
              validator: this.passwordConfirmValidate,
              message: "两次密码输入不一致",
            },
          ],
        },
        {
          title: "角色",
          name: "role_name",
          type: "select",
          values: ['导游', '讲解员'],
          required: true,
          rule: [{ required: true, message: "" }],
          inline: false
        },
        {
          title: "省市区选择",
          name: "locationStr",
          type: "area",
          inline: false,
          required: true,
          rule: [{ required: true, message: "" }],
        },
      ],
      guideFormFields: [
        {
          title: "导游证号",
          name: "tourist_card",
          type: "text",
          inline: false,
          required: true,
          rule: [
            {
              validator: validateNumberAndLetter,
              message: "请输入正确的格式"
            },
            {
              required: true,
              message: ""
            }
          ],
        },
        {
          title: "导游证照",
          name: "tourist_card_image",
          type: "file",
          inline: false,
          max_count: 1,
          rule: [],
        },
        {
          title: "导游资格证号",
          name: "tourist_certificate",
          type: "text",
          required: true,
          inline: false,
          rule: [
            {
              validator: validateNumberAndLetter,
              message: "请输入正确的格式"
            },
            {
              required: true,
              message: ""
            }
          ],
        },
        {
          title: "导游资格证照",
          name: "tourist_certificate_image",
          type: "file",
          inline: false,
          multiple: true,
          rule: [],
        },
        {
          title: "导游证起始日",
          name: "establish_date",
          type: "date_select",
          required: true,
          inline: false,
          maxDate: new Date(new Date().getFullYear() + 20, 11, 31),
          minDate: new Date(new Date().getFullYear() - 20, 1, 1),
          rule: [
            {
              required: true,
              message: ""
            }
          ],
        },
        {
          title: "导游证有效期",
          name: "valid_period",
          type: "date_select",
          maxDate: new Date(new Date().getFullYear() + 20, 11, 31),
          inline: false,
          required: true,
          customField: true,
          rule: [
            {
              required: true,
              message: ""
            }
          ],
        },
        {
          title: "语种",
          name: "language",
          type: "multi_select",
          values: [
            { id: 1, value: '普通话'}, 
            { id: 2, value: '英语'}, 
            { id: 3, value: '其他'}, 
          ],
          inline: false,
          required: true,
          rule: [{required: true, message: ""}],
        },
        {
          title: "导游等级",
          name: "level",
          type: "select",
          values: ['初级', '中级', '高级', '特级'],
          inline: false,
          required: true,
          rule: [{required: true, message: ""}],
        },
      ],
      jiangjieFormFields: [
         {
          title: "讲解员证号",
          name: "tourist_card",
          type: "text",
          required: true,
          inline: false,
          rule: [
            {
              validator: validateNumberAndLetter,
              message: "请输入正确的格式"
            },
            {
              required: true,
              message: ""
            }
          ],
        },
        {
          title: "讲解员证照",
          name: "tourist_card_image",
          type: "file",
          inline: false,
          max_count: 1,
          rule: [],
        },
        {
          title: "讲解员资格证号",
          name: "tourist_certificate",
          type: "text",
          inline: false,
          required: true,
          rule: [
            {
              validator: validateNumberAndLetter,
              message: "请输入正确的格式"
            },
            {
              required: true,
              message: ""
            }
          ],
        },
        {
          title: "讲解员资格证照",
          name: "tourist_certificate_image",
          type: "file",
          inline: false,
          multiple: true,
          rule: [],
        },
        {
          title: "讲解员证起始日",
          name: "establish_date",
          type: "date_select",
          required: true,
          inline: false,
          maxDate: new Date(new Date().getFullYear() + 20, 11, 31),
          rule: [
            {
              required: true,
              message: ""
            }
          ],
        },
        {
          title: "讲解员证有效期",
          name: "valid_period",
          type: "date_select",
          required: true,
          customField: true,
          inline: false,
          maxDate: new Date(new Date().getFullYear() + 20, 11, 31),
          rule: [
            {
              required: true,
              message: ""
            }
          ],
        },
        {
          title: "语种",
          name: "language",
          type: "multi_select",
          values: [
            { id: 1, value: '普通话'}, 
            { id: 2, value: '英语'}, 
            { id: 3, value: '其他'}, 
          ],
          inline: false,
          required: true,
          rule: [{required: true, message: ""}],
        },
      ]
    };
  },
  mounted() {
    this.opFields = this.fields
    const fieldsStr = JSON.stringify(this.fields)
    this.opFields = JSON.parse(fieldsStr)
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    register() {
      this.user.tourist_certificate_image = this.user.tourist_certificate_image.join()
      const user = JSON.parse(JSON.stringify(this.user))
      if(user.valid_period == '长期') {
        user.valid_period = new Date(2999, 11, 31)
      }
      api.register(user).then((res) => {
        if(res.data.status == 200) {
          this.$router.push("/login");
        } else {
          Toast(res.data.message)
        }
      })
    },
    onDateSelect(item) {
      this.currentDateSelectItem = item
      this.showDateTypeSelection = true
    },
    onDateTypeSelect(item) {
      console.log(item);
      if(item.name == '长期') {
        this.user.valid_period = item.name
      } else {
        this.showDateSelect = true
        this.showDateTypeSelection = false
      }
    },
    onDateConfirm(date){
      this.user[this.currentDateSelectItem.name] = `${date.getFullYear()}-${
        date.getMonth() + 1 }-${date.getDate()}`
      this.showDateSelect = false
    },
    nameLengthValidate(val) {
      return val.length < 6
    },
    passwordConfirmValidate(val) {
      return val === this.user.password;
    },
    passwordLengthValidate(val) {
      return val.length >= 6
    },
    onGenderConfirm(val) {
      this.user.gender = val;
      this.showPicker = false;
    },
    onLevelConfirm(val) {
      this.user.level = val;
      this.showLevelPicker = false;
    },
    onClickBack() {
      this.$router.push('/login')
    },
    mainFormUpdate(data) {
      this.opFields.splice(0, this.opFields.length)
      const fieldsStr = JSON.stringify(this.fields)
      this.opFields = JSON.parse(fieldsStr)
      if(data.role_name == '导游') {
        this.guideFormFields.forEach(ele => {
          this.opFields.splice(this.opFields.length - 1, 0, ele)
        })
      } else if(data.role_name == '讲解员'){
        this.jiangjieFormFields.forEach(ele => {
          this.opFields.splice(this.opFields.length - 1, 0, ele)
        })
      }
    }
  },
};
</script>

<style scoped>
::v-deep .van-popup--bottom {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
::v-deep .card-item {
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 20px;
  border-radius: 20px;
  padding: 0.1rem;
  position: relative;
  margin-bottom: 10px;
}
::v-deep .van-uploader__preview-image {
    float: right;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;
}
::v-deep .van-uploader__upload {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 5px 0 0 5px;
    background-color: #f7f8fa;
}
::v-deep .van-uploader__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    float: right;
}
::v-deep .van-uploader__preview {
    position: relative;
    margin: 5px 0 0 5px;
    cursor: pointer;
}
</style>